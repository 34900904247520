html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  width: 100%;
}

.text-part {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.text-part.no-name {
  padding-top: 0;
  padding-bottom: 0;
}

.text-part.no-name .text-start-time {
  display: none;
}

.text-part.no-name .text-part-name {
  display: none;
}

.gutter {
  background: rgba(63, 63, 63, 0.886);
}